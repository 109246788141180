<template>
    <div class="email-us">
        <h2 v-html="$t('contact.heading')"></h2>

        <p v-html="$t('contact.text')"></p>

        <img v-if="device.includes('desktop')" class="send-message-arrow" src="/img/send-message-arrow.png" alt="">

        <form
            id="submit-mail-form"
            @submit.prevent="sendEmail"
            class="form"
        >
            <div v-if="form.response" class="notification">
                <div
                    class="notification__message"
                    :class="{
                        'notification__message--success': form.response.icon === 'success',
                        'notification__message--error': form.response.icon === 'error'
                    }"
                >
                    <div class="icon-background">
                        <svg-icon :icon="`notifications/${form.response.icon}`" />
                    </div>

                    <p class="notification__text" v-html="form.response.message">
                    </p>

                    <button class="notification__close" @click="closeAndReloadPage()">
                        <svg-icon icon="notifications/error" />
                    </button>
                </div>
            </div>

            <h3 v-html="$t('contact.form.heading')"></h3>

            <!-- EMAIL -->
            <div class="form__group">
                <label v-html="$t('contact.form.email.label')"></label>

                <input
                    type="email"
                    v-model="$v.form.email.$model"
                    name="email"
                    :placeholder="$t('contact.form.email.placeholder')"
                    class="form__input"
                />

                <p
                    v-if="!$v.form.email.required && $v.form.email.$error"
                    v-html="$t('validations.required')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.email.email && $v.form.email.$error"
                    v-html="$t('validations.email')"
                    class="form__validation-error"
                >
                </p>
            </div>

            <!-- NAME -->
            <div class="form__group">
                <label v-html="$t('contact.form.name.label')"></label>

                <input
                    type="text"
                    v-model="$v.form.name.$model"
                    name="name"
                    :placeholder="$t('contact.form.name.placeholder')"
                    class="form__input"
                />

                <p
                    v-if="!$v.form.name.required && $v.form.name.$error"
                    v-html="$t('validations.required')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.name.alphaNumAndDotValidator && $v.form.name.$error"
                    v-html="$t('validations.alphaNumAndDotValidator')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.name.minLength && $v.form.name.$error"
                    v-html="$t('validations.nameTooShort')"
                    class="form__validation-error"
                >
                </p>
            </div>

            <!-- PHONE NUMBER -->
            <div class="form__group">
                <label v-html="$t('contact.form.phone.label')"></label>

                <input
                    type="tel"
                    v-model="$v.form.phoneNumber.$model"
                    name="phone"
                    :placeholder="$t('contact.form.phone.placeholder')"
                    class="form__input"
                />

                <p
                    v-if="!$v.form.phoneNumber.required && $v.form.phoneNumber.$error"
                    v-html="$t('validations.required')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.phoneNumber.numeric && $v.form.phoneNumber.$error"
                    v-html="$t('validations.numeric')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.phoneNumber.minLength && $v.form.phoneNumber.$error"
                    v-html="$t('validations.minLength', { count: $v.form.phoneNumber.$params.minLength.min })"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.phoneNumber.maxLength && $v.form.phoneNumber.$error"
                    v-html="$t('validations.maxLength', { count: $v.form.phoneNumber.$params.maxLength.max })"
                    class="form__validation-error"
                >
                </p>
            </div>

            <!-- SUBJECT -->
            <div class="form__group">
                <label v-html="$t('contact.form.subject.label')"></label>

                <input
                    type="text"
                    v-model="$v.form.subject.$model"
                    name="subject"
                    :placeholder="$t('contact.form.subject.placeholder')"
                    class="form__input"
                />

                <p
                    v-if="!$v.form.subject.required && $v.form.subject.$error"
                    v-html="$t('validations.required')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.subject.alphaNumAndDotValidator && $v.form.subject.$error"
                    v-html="$t('validations.alphaNumAndDotValidator')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.subject.minLength && $v.form.subject.$error"
                    v-html="$t('validations.minLength', { count: $v.form.subject.$params.minLength.min })"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.subject.maxLength && $v.form.subject.$error"
                    v-html="$t('validations.maxLength', { count: $v.form.subject.$params.maxLength.max })"
                    class="form__validation-error"
                >
                </p>
            </div>

            <!-- DESCRTIPTION -->
            <div class="form__group">
                <label v-html="$t('contact.form.description.label')"></label>

                <textarea
                    cols="30"
                    rows="5"
                    maxlength="1500"
                    v-model="$v.form.description.$model"
                    name="description"
                    :placeholder="$t('contact.form.description.placeholder')"
                    class="form__input"
                >
                </textarea>

                <p
                    v-if="!$v.form.description.required && $v.form.description.$error"
                    v-html="$t('validations.required')"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.description.minLength && $v.form.description.$error"
                    v-html="$t('validations.minLength', { count: $v.form.description.$params.minLength.min })"
                    class="form__validation-error"
                >
                </p>

                <p
                    v-else-if="!$v.form.description.maxLength && $v.form.description.$error"
                    v-html="$t('validations.maxLength', { count: $v.form.description.$params.maxLength.max })"
                    class="form__validation-error"
                >
                </p>
            </div>

            <label class="checkbox-container">
                {{ $t('contact.form.privacyPolicyAgreed.first') }}

                <router-link to="/terms">{{ $t('contact.form.privacyPolicyAgreed.second') }}</router-link>.

                <input type="checkbox" v-model="$v.form.privacyPolicyAgreed.$model">

                <span class="checkmark"></span>
            </label>

            <button
                v-html="$t('contact.form.buttonText')"
                type="submit"
                class="form__submit"
                :class="{ 'form__submit--disabled': $v.form.$invalid}"
                :disabled="$v.form.$invalid"
            >
            </button>

            <img v-if="device.includes('desktop')" src="/img/message-dots.png" alt="" class="send-message-dots">
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SvgIcon from '@/components/SvgIcon'

import emailjs from 'emailjs-com'
import { required, numeric, minLength, maxLength, email, helpers, sameAs } from 'vuelidate/lib/validators'

const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z_ ]*$/i)

export default {
    name: 'EmailUs',
    components: {
        SvgIcon,
    },
    data() {
        return {
            form: {
                email: '',
                name: null,
                phoneNumber: null,
                subject: null,
                description: null,
                privacyPolicyAgreed: null,
                response: null,
            }
        }
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            name: {
                required,
                alphaNumAndDotValidator,
                minLength: minLength(2),
            },
            phoneNumber: {
                required,
                numeric,
                minLength: minLength(8),
                maxLength: maxLength(12),
            },
            subject: {
                required,
                alphaNumAndDotValidator,
                minLength: minLength(10),
                maxLength: maxLength(50),
            },
            description: {
                required,
                minLength: minLength(50),
                maxLength: maxLength(1000),
            },
            privacyPolicyAgreed: {
                sameAs: sameAs(() => true) // Not working with require as flase is apparently also true haha
            },
        },
    },
    computed: {
        ...mapState(['device']),
    },
    methods: {
        sendEmail() {
            if (this.$v.form.$invalid) return console.log('Invalid form')
            emailjs.sendForm('service_2kp1nug', 'template_569zag8', 'submit-mail-form', 'user_NRgsk5wt33jlBlMzn1FAW')
                .then(() => {
                    this.form.response = this.responseDetails('success')
                }, (error) => {
                    this.form.response = this.responseDetails('error')
                    console.log('SendEmail error: ', error)
                })
        },
        responseDetails(response) {
            let success = {
                icon: 'success',
                message: this.$t('contact.notifications.success'),
            }

            let error = {
                icon: 'error',
                message: this.$t('contact.notifications.error'),
            }

            return response === 'success' ? success : error
        },
        closeAndReloadPage() {
            location.reload()
        },
    },
}
</script>

<style lang="scss">
.email-us {
    position: relative;
    z-index: 2;
    margin: 0 -18px;
    padding: 48px 18px;
    color: white;
    background-color: #616062;

    @media (min-width: 768px) {
        padding-bottom: 90px;
    }

    @media (min-width: 1024px) {
        padding-bottom: 120px;
    }

    p {
        margin: 6px 0;
        font-size: 14px;

        @media (min-width: 768px) {
            margin-bottom: 54px;
            font-size: 20px;
            text-align: center;
        }
    }

    .send-message-arrow {
        width: 100%;
        margin-bottom: 12px;

        @media (min-width: 768px) {
            position: absolute;
            top: 60%;
            left: 50%;
            width: 90%;
            transform: translate(-50%, -50%);
        }
    }

    .send-message-dots {
        position: absolute;
        right: -90px;
        bottom: -50px;
    }

    .form {
        display: flex;
        position: relative;
        padding: 18px;
        flex-direction: column;
        border-radius: 40px;
        background-color: white;
        box-shadow: #0e77ff26 0 10px 30px;

        @media (min-width: 768px) {
            width: 450px;
            margin-left: 48px;
            padding: 24px;
        }

        @media (min-width: 1024px) {
            width: 550px;
            margin-left: 126px;
        }

        @media (min-width: 1300px) {
            width: 600px;
            margin-left: 200px;
        }

        h3 {
            margin: 0 0 12px;
            color: black;
            font-size: 18px;
            text-align: center;

            @media (min-width: 768px) {
                font-size: 20px;
            }
        }

        label {
            color: black;
            font-size: 12px;

            @media (min-width: 768px) {
                font-size: 14px;
            }
        }

        input[type=text], input[type=email], input[type=tel] {
            height: 24px;
            font-size: 16px;

            &::placeholder {
                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        textarea {
            resize: none;

            &::placeholder {
                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .notification {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 40px;
            background-color: rgba(0, 0, 0, .8);

            &__message {
                display: flex;
                position: absolute;
                top: 30%;
                width: 100%;
                height: 60px;
                border-radius: 6px;

                @media (min-width: 375px) {
                    height: 80px;
                }

                &--success {
                    background-color: #91c191;

                    .icon-background {
                        background-color: #4caf50;
                    }

                    .notification__text {
                        font-size: 12px;

                        @media (min-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    .notification__close {
                        path {
                            fill: #4caf50;
                        }
                    }
                }

                &--error {
                    background-color: #ffdddd;

                    .icon-background {
                        background-color: #f44336;
                    }

                    .notification__close {
                        path {
                            fill: #f44336;
                        }
                    }
                }
            }

            &__text {
                margin: auto;
                padding: 0 16px;
                font-size: 10px;

                @media (min-width: 375px) {
                    padding: 0 18px;
                    font-size: 12px;
                }

                @media (min-width: 768px) {
                    padding: 0 24px;
                }
            }

            &__close {
                position: absolute;
                top: 0;
                right: 6px;
                bottom: 0;
                padding: 0;
                border: none;
                background-color: transparent;

                @media (min-width: 375px) {
                    right: 12px;
                }

                .svg-container {
                    font-size: 10px !important;

                    @media (min-width: 768px) {
                        font-size: 12px !important;
                    }
                }
            }

            .icon-background {
                display: flex;
                min-width: 30px;
                height: 100%;
                border-radius: 6px 6px 0 6px;

                @media (min-width: 375px) {
                    min-width: 50px;
                }

                @media (min-width: 768px) {
                    min-width: 80px;
                }
            }

            .svg-container {
                margin: auto;
                font-size: 12px;

                @media (min-width: 375px) {
                    font-size: 24px;
                }
            }
        }

        &__submit {
            width: 80px;
            margin: auto 0 auto auto;
            padding: 4px 0;
            border: none;
            border-radius: 6px;
            outline: none;
            color: white;
            background-color: #0e77ff;

            @media (min-width: 768px) {
                width: 120px;
                font-size: 18px;
            }

            &--disabled {
                background-color: lightgray;
            }
        }

        &__group {
            display: flex;
            position: relative;
            flex-direction: column;
        }

        &__input {
            position: relative;
            margin-bottom: 12px;
            border: none;
            border-bottom: 1px solid black;
            outline: none;
        }

        &__validation-error {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 2px;
            color: red;
            font-size: 10px;
        }

        .checkbox-container {
            display: block;
            position: relative;
            margin-bottom: 12px;
            padding-left: 24px;
            font-size: 12px;
            cursor: pointer;
            user-select: none;

            input {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
                cursor: pointer;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #c9c9c9;
                border-radius: 50%;
                background-color: white;

                &::after {
                    display: none;
                    position: absolute;
                    background-color: #2196f3;
                    content: "";
                }
            }

            &:hover input ~ .checkmark {
                background-color: white;
            }
        }

        .checkbox-container input:checked ~ .checkmark::after {
            display: block;
        }

        .checkbox-container .checkmark::after {
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            transform: translate(-50%, -50%);
            border-width: 0 3px 3px 0;
            border-radius: 50%;
        }
    }
}
</style>
